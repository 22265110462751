import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { isMobile } from "react-device-detect";

import logo from "../img/logo.png";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import pinterest from "../img/social/pinterest.svg";
import { BLUE, CREAM } from "../config/colors";

const Footer = () => {
	const getWindowDimensions = () => {
		if (typeof window === "undefined") {
			return { width: isMobile ? 600 : 1100, height: isMobile ? 600 : 1100 };
		}
		const { innerWidth: width, innerHeight: height } = window;
		return {
			width,
			height,
		};
	};

	const useWindowDimensions = () => {
		const [windowDimensions, setWindowDimensions] = useState(
			getWindowDimensions()
		);

		useEffect(() => {
			function handleResize() {
				setWindowDimensions(getWindowDimensions());
			}

			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}, []);

		return windowDimensions;
	};
	const { width: screenWidth } = useWindowDimensions();
	const isFooterFlat = screenWidth > 900;

	return (
		<footer
			className="footer has-text-white-ter"
			style={{ backgroundColor: CREAM, paddingBottom: 60 }}
		>
			<div className="content has-text-centered">
				<img
					src={logo}
					alt="A stroke of life logo"
					style={{ width: "20em", height: "4em", marginBottom: 30 }}
				/>
			</div>
			<div
				className="content has-text-centered has-text-white-ter"
				style={{ backgroundColor: CREAM }}
			>
				<div
					className="container has-text-white-ter"
					style={{
						backgroundColor: CREAM,
						paddingLeft: "20px",
						paddingRight: "20px",
					}}
				>
					<div
						className="is-vcentered has-text-centered is-centered"
						style={{
							display: "flex",
							flexDirection: isFooterFlat ? "row" : "column",
							justifyContent: "space-evenly",
							paddingLeft: "60px",
							paddingRight: "60px",
						}}
					>
						<Link className="footer-item" to="/">
							Home
						</Link>

						<Link className="footer-item" to="/about">
							About
						</Link>
						<Link className="footer-item" to="/blog">
							Blog
						</Link>
						<Link className="footer-item" to="/podcast">
							Podcast
						</Link>
						<Link className="footer-item" to="/contact">
							Contact
						</Link>

						<div
							className="social"
							style={{ marginTop: "8px", padding: "1em" }}
						>
							<a title="Facebook" href="https://facebook.com/astrokeoflife">
								<img
									src={facebook}
									alt="Facebook"
									style={{ width: "1em", height: "1em" }}
								/>
							</a>
							<a
								title="Stroke Art Instagram"
								href="https://www.instagram.com/str.okeart/"
							>
								<img
									src={instagram}
									alt="Stroke Art Instagram"
									style={{ width: "1em", height: "1em" }}
								/>
							</a>
							<a title="Pinterest" href="https://pinterest.com/astrokeoflife">
								<img
									src={pinterest}
									alt="Pinterest"
									style={{ width: "1em", height: "1em" }}
								/>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div
				style={{
					float: "right",
					marginRight: "50px",
					marginTop: "20px",
					color: BLUE,
				}}
			>
				© {new Date().getFullYear()} A Stroke of Life
			</div>
		</footer>
	);
};

export default Footer;
