import React from "react";
import { Link } from "gatsby";
import smallLogo from "../img/smallLogo.png";

const Navbar = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			navBarActiveClass: "",
		};
	}

	toggleHamburger = () => {
		// toggle the active boolean in the state
		this.setState(
			{
				active: !this.state.active,
			},
			// after state has been updated,
			() => {
				// set the class in state for the navbar accordingly
				this.state.active
					? this.setState({
							navBarActiveClass: "is-active",
					  })
					: this.setState({
							navBarActiveClass: "",
					  });
			}
		);
	};

	render() {
		return (
			<nav
				className="navbar is-transparent"
				role="navigation"
				aria-label="main-navigation"
				style={{ backgroundColor: "#FFF3E9" }}
			>
				<div className="container">
					<div className="navbar-brand">
						<Link to="/" className="navbar-item" title="Logo">
							<img
								src={smallLogo}
								alt="A stroke of life logo"
								style={{ width: "44px", height: "44px" }}
							/>
						</Link>
						<div
							role="button"
							tabindex="0"
							onKeyDown={this.handleClick}
							className={`navbar-burger burger ${this.state.navBarActiveClass}`}
							data-target="navMenu"
							onClick={() => this.toggleHamburger()}
						>
							<span />
							<span />
							<span />
						</div>
					</div>
					<div
						id="navMenu"
						className={`navbar-menu ${this.state.navBarActiveClass}`}
					>
						<div className="navbar-start has-text-centered">
							<Link className="navbar-item" to="/about">
								About
							</Link>
							<Link className="navbar-item" to="/blog">
								Blog
							</Link>
							<Link className="navbar-item" to="/podcast">
								Podcast
							</Link>
							<Link className="navbar-item" to="/contact">
								Contact
							</Link>
						</div>
					</div>
				</div>
			</nav>
		);
	}
};

export default Navbar;
